import React from "react";

import PropTypes from "prop-types";

import styles from "./ErrorPage.module.scss";

const ErrorPage = ({ text }) => {
    return (
        <div className={styles.ErrorPage}>
            <div className={styles.ErrorContainer}>{text}</div>
        </div>
    );
};

ErrorPage.propTypes = {
    text: PropTypes.string.isRequired
};

export default ErrorPage;
