import React from "react";
import styles from "./LoadingPage.module.scss";

const LoadingPage = () => {
    return (
        <div className={styles.LoadingPage}>
            <div className={styles.LoaderContainer}>
                <div className={styles.Loader}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingPage;
