import React, { Suspense, useEffect, useMemo, useState } from "react";
import SliderStyles from "@livecasino/core/v2/components/Slider/SliderStyles";
import getDeviceInfo from "@livecasino/core/helpers/getDeviceInfo";
import useGetAspectRatioSizesAndFontSize from "@livecasino/core/hooks/useGetAspectRatioSizesAndFontSize";
import useGameMobileSizesCalculation from "@livecasino/core/hooks/useGameMobileSizesCalculation";
import { POST_MESSAGE_ACTIONS } from "@livecasino/core/constants/postMessageActions";
import getParamsFromUrl from "@livecasino/core/helpers/getParamsFromUrl";
import useAuth from "@livecasino/core/hooks/useAuth";
import useSetIframeUrl from "@livecasino/core/hooks/useSetIframeUrl";
import useVolume from "@livecasino/core/hooks/useVolume";
import sendPostMessageToChildFrames from "@livecasino/core/helpers/sendPostMessageToChildFrames";
import useStaticCallback from "@livecasino/core/hooks/useStaticCallback";
import { GAME_TYPES } from "@livecasino/core/constants/gameTypes";
import { AUTH_URL, BET_LIMITS_URL, GAME_CONFIG_URL, HELP_TRANSLATIONS_ENDPOINT } from "./constants";
import LoadingPage from "./LoadingPage/LoadingPage";
import ErrorPage from "./ErrorPage/ErrorPage";

import "./index.css";

const { isMobile } = getDeviceInfo();
const ext = isMobile ? "mobile" : "desktop";
const HelpLazy = React.lazy(() => import(`./Help/index.${ext}.js`));

const SUPPORTED_GAME_TYPES_DATA = {
    [GAME_TYPES.AMERICAN_BLACKJACK]: {
        folder: "Blackjack",
        section: "blackjack"
    },
    [GAME_TYPES.IMMERSIVE_BLACKJACK]: {
        folder: "Blackjack",
        section: "blackjack"
    },
    [GAME_TYPES.EUROPEAN_ROULETTE]: {
        folder: "Roulette",
        section: "roulette"
    },
    [GAME_TYPES.AUTO_ROULETTE]: {
        folder: "Roulette",
        section: "roulette"
    },
    [GAME_TYPES.AMERICAN_ROULETTE]: {
        folder: "AmericanRoulette",
        section: "roulette"
    },
    [GAME_TYPES.MULTIPLIER_ROULETTE]: {
        folder: "MultiplierRoulette",
        section: "roulette"
    },
    [GAME_TYPES.SPEED_BACCARAT]: {
        folder: "Baccarat",
        section: "baccarat"
    },
    [GAME_TYPES.SPEED_DRAGON_TIGER]: {
        folder: "DragonTiger",
        section: "dragonTiger"
    },
    [GAME_TYPES.SICBO]: {
        folder: "Sicbo",
        section: "sicbo"
    },
    [GAME_TYPES.PIRATE_PLUNDER]: {
        folder: "PiratePlunder",
        section: "piratePlunder"
    },
    [GAME_TYPES.ANDAR_BAHAR]: {
        folder: "AndarBahar",
        section: "andarBahar"
    },
    [GAME_TYPES.ROBIN_THE_FAIR]: {
        folder: "RobinTheFair",
        section: "robinTheFair"
    },
    [GAME_TYPES.CASINO_HOLDEM]: {
        folder: "CasinoHoldem",
        section: "casinoHoldem"
    }
};

const DEFAULT_LANGUAGE_CODE = "en";

const RIGHT_TO_LEFT_LANGUAGES = ["fa"];

const params = getParamsFromUrl(window.location.href, { decode: false });

const { token, refreshToken, languageCode, gameType, initialVolume, partnerPrefix, playerId, tableId } = params;

function App() {
    const { requestInstance } = useAuth({
        token,
        refreshToken,
        frameUrl: AUTH_URL,
        keyPrefix: partnerPrefix
    });

    const [state, setState] = useState({
        loading: true,
        data: [],
        content: {},
        languageCode: String(languageCode).toLowerCase(),
        error: ""
    });

    const { volume, setVolumeChange } = useVolume(initialVolume);

    const { iframeUrl: betLimitsUrl } = useSetIframeUrl({
        url: BET_LIMITS_URL,
        token,
        refreshToken,
        partnerPrefix,
        playerId,
        tableId,
        gameType
    });

    const getTranslationObject = useStaticCallback(() => {
        return state.content;
    });

    useEffect(() => {
        const messageToSend = {
            action: POST_MESSAGE_ACTIONS.TRANSLATION_OBJECT,
            payload: JSON.stringify(state.content)
        };
        sendPostMessageToChildFrames(messageToSend);
    }, [state.content]);

    useEffect(() => {
        window.addEventListener("message", handleMessage);
        function handleMessage(e) {
            const message = e.data;
            if (message?.action === POST_MESSAGE_ACTIONS.GET_TRANSLATION_OBJECT) {
                const translation = JSON.stringify(getTranslationObject());
                const messageToSend = {
                    action: POST_MESSAGE_ACTIONS.TRANSLATION_OBJECT,
                    payload: translation
                };
                sendPostMessageToChildFrames(messageToSend);
            }
        }
        return () => window.removeEventListener("message", handleMessage);
    }, [getTranslationObject]);

    const isRightToLeftLanguage = useMemo(
        () => RIGHT_TO_LEFT_LANGUAGES.includes(state.languageCode),
        [state.languageCode]
    );

    const { fontSize: desktopFontSize } = useGetAspectRatioSizesAndFontSize();
    const { fontSize: mobileFontSize } = useGameMobileSizesCalculation({
        scaleRatio: 1
    });

    const fontSize = isMobile ? mobileFontSize * 0.8 : desktopFontSize * 2.5;

    useEffect(() => {
        const gameTypeFolderName = SUPPORTED_GAME_TYPES_DATA[gameType]?.folder;
        const gameTypeSectionName = SUPPORTED_GAME_TYPES_DATA[gameType]?.section;
        if (!gameTypeFolderName || !gameTypeSectionName) {
            return;
        }

        const bodyElement = document.querySelector("body");
        if (!bodyElement) {
            return;
        }

        if (isRightToLeftLanguage) {
            const directionAttribute = document.createAttribute("dir");
            directionAttribute.value = "rtl";
            bodyElement.setAttributeNode(directionAttribute);
        } else {
            bodyElement.removeAttribute("dir");
        }

        getHelpContent(gameTypeFolderName, gameTypeSectionName, state.languageCode, tableId, playerId)
            .then(({ content, helpContent }) => {
                setState(prevState => ({
                    ...prevState,
                    data: helpContent,
                    content,
                    loading: false
                }));
            })
            // .catch(() => {
            //     setState(prevState => ({
            //         ...prevState,
            //         loading: false,
            //         error: "Something went wrong ..."
            //     }));
            // });
            .finally(() => {
                setState(prevState => ({
                    ...prevState,
                    loading: false
                }));
            });

        async function getComponentsListBuilder(gameTypeFolderName) {
            const response = await import(`./games/${gameTypeFolderName}/index.js`);
            return response.default;
        }

        async function getGameConfig({ tableId, playerId }) {
            let configObj;

            try {
                if (!tableId || !playerId) {
                    await Promise.reject();
                }
                const response = await requestInstance.get(
                    `${GAME_CONFIG_URL}?tableId=${Number(tableId)}&playerId=${Number(playerId)}`
                );

                configObj = response?.data || {};
            } catch (error) {
                return {};
            }
            return configObj;
        }

        async function getComponentsContentObj(
            gameTypeFolderName,
            gameTypeSectionName,
            languageCode,
            translationVersion
        ) {
            let languageFile;
            let defaultLanguageFile;
            try {
                defaultLanguageFile = await getDefaultLanguageContentObj(gameTypeFolderName);
                const url = `${HELP_TRANSLATIONS_ENDPOINT}/${gameTypeSectionName}_help/${languageCode}/${translationVersion}`;

                const { data: translatedLanguageFile } = await requestInstance.get(url);

                languageFile = { ...defaultLanguageFile, ...translatedLanguageFile };
            } catch (e) {
                languageFile = defaultLanguageFile;
            }
            return languageFile;
        }

        async function getDefaultLanguageContentObj(gameTypeFolderName) {
            const response = await import(`./games/${gameTypeFolderName}/content/${DEFAULT_LANGUAGE_CODE}.js`);
            return response.default;
        }

        async function getHelpContent(gameTypeFolderName, gameTypeSectionName, languageCode, tableId, playerId) {
            const data = await getGameConfig({ tableId, playerId });
            let gameConfig = {};
            if (data) {
                gameConfig = {
                    ...data.configs,
                    currencyCode: data.currencyCode,
                    gameName: data.gameName,
                    plankMultipliers: data.plankMultipliers,
                    bonusCardConfiguration: data.bonusCardConfiguration
                };
            }

            const requests = [
                getComponentsListBuilder(gameTypeFolderName),
                getComponentsContentObj(
                    gameTypeFolderName,
                    gameTypeSectionName,
                    languageCode,
                    gameConfig.translationVersion
                )
            ];

            const response = await Promise.all(requests);
            const builder = response[0];
            const content = response[1];

            const helpContent = builder({ content, fontSize, gameConfig, betLimitsUrl });

            return { content, helpContent };
        }
    }, [state.languageCode, requestInstance, fontSize, isRightToLeftLanguage, betLimitsUrl]);

    useEffect(() => {
        window.addEventListener("message", handleMessage);

        function handleMessage(e) {
            const message = e.data;

            if (message?.action === POST_MESSAGE_ACTIONS.CHANGE_LANGUAGE) {
                const languageCode = String(message.payload).toLowerCase();
                setState(prevState => {
                    return {
                        ...prevState,
                        languageCode
                    };
                });
            }
            if (message?.action === POST_MESSAGE_ACTIONS.CHANGE_VOLUME) {
                setVolumeChange(message.payload);
            }
        }

        return () => window.removeEventListener("message", handleMessage);
    }, [setVolumeChange]);

    return (
        <>
            <SliderStyles />
            <Suspense fallback={<LoadingPage />}>
                {state.loading && <LoadingPage />}
                {state.error && <ErrorPage text={state.error} />}
                {!state.loading && !state.error && (
                    <HelpLazy
                        data={state.data}
                        volume={volume}
                        isRightToLeftLanguages={isRightToLeftLanguage}
                        fontSize={fontSize}
                    />
                )}
            </Suspense>
        </>
    );
}

export default App;
